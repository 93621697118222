<template>
  <div>
    <div
      style="position: absolute;top:10%;width:100%;z-index:99"
      v-if="active && !flag_student"
    >
      <!-- 因为postcss-pxtorem库会自动将非行内样式的px转换为rem,而该页面采用固定宽高 所以全部采用行内样式 -->
      <div
        style="width:340px;margin:0 auto;position:relative"
        v-show="!flag"
      >
        <img
          style="width:100%"
          :src="img_src"
          alt=""
        >
        <div style="width:74px;height:74px;border-radius: 50%;position: absolute;top:5%;left:38.9%;background-color:#fff;overflow: hidden;">
          <img
            style="width:100%;height:100%"
            :src="user.headimgurl"
            alt=""
          >
        </div>
        <p style="font-size:14px;color:#967b3c;margin: 0;position: absolute;top: 119px;left: 50%;text-align:center;transform: translateX(-50%);min-width: 180px;">来自{{nicknameFn(user.nickname,6)}}的助力</p>
        <p style="font-size:16px;color:#746253;margin: 0;position: absolute;top: 200px;left: 50%;text-align:center;transform: translateX(-50%);min-width: 180px;">您已为您的好友</p>
        <p style="font-size:16px;color:#746253;margin: 0;position: absolute;top: 230px;left: 50%;text-align:center;transform: translateX(-50%);min-width: 260px;">@{{nicknameFn(refer.nickname,8)}} &nbsp;增加助力值</p>
        <p style="font-size:14px;color:#746253;margin: 0;position: absolute;top: 328px;left: 50%;text-align:center;transform: translateX(-50%);min-width: 260px;">每人仅可助力成功一次，反复助力无效哦</p>
        <p
          style="text-decoration: underline;font-size:16px;color:#746253;margin: 0;position: absolute;top: 370px;left: 50%;text-align:center;transform: translateX(-50%);min-width: 260px;"
          @click="goPoster"
        >{{link_text}}</p>
        <p
          style="text-decoration: underline;font-size:16px;color:#746253;margin: 0;position: absolute;top: 405px;left: 50%;text-align:center;transform: translateX(-50%);min-width: 260px;"
          @click="goMy"
        >{{profile_link}}</p>
      </div>

    </div>
    <!-- 学院系统展示相关内容 -->
    <div
      style="position: absolute;top:10%;width:100%;z-index:99"
      v-if="flag_student "
    >
      <div style="width:340px;margin:0 auto;position:relative">
        <img
          style="width:100%"
          :src="img_src"
          alt=""
        >
        <p
          style="text-decoration: underline;font-size:16px;color:#746253;margin: 0;position: absolute;top: 405px;left: 50%;text-align:center;transform: translateX(-50%);min-width: 260px;"
          @click="goStudent"
        >{{text}}</p>
      </div>
    </div>

    <!-- 信息获取失败提示 -->
    <div
      class="tip-box"
      v-show="flag"
    >
      <div style="font-size:18px;text-align: center; line-height:1;">提示</div>
      <div style="font-size:16px;text-align: center; margin-top:5px;line-height:1;">{{errmsg}}</div>
    </div>

    <!-- 嵌入poster页面的iframe -->
    <div class="poster">
      <iframe
        :src="iframeUrl"
        width="100%"
        height="100%"
        frameborder="0"
      ></iframe>
    </div>

    <!-- 活动关闭展示的图片 -->
    <div v-if="!active && !flag_student">
      <img
        class="deactive_img"
        :src="deactive_img"
        alt=""
      >
      <!-- <img class="deactive_img"  :src="deactive_img" alt=""> -->
    </div>

  </div>
</template>

<script>
import axios from 'axios'
export default {
  data () {
    return {
      flag: false,
      errmsg: '',
      headimgurl: "https://ceshiren.com/uploads/default/original/1X/809c63f904a37bc0c6f029bbaf4903c27f03ea8a.png",
      nickname: "霍格沃兹测试学院",
      user: {
        headimgurl: '',
        nickname: ''
      },
      refer: {
        nickname: '',
        headimgurl: '',
      },
      title: '',
      query: {},
      img_src: '',
      iframeUrl: '',
      link_text: '',
      profile_link: '',
      active: false,
      deactive_img: '',
      flag_student: false, //学员系统相关背景图
      text: '',//学员系统相关问题链接text
      link: ''//学员系统相关链接link
    }
  },
  methods: {
    //跳转到学员系统
    goStudent () {
      location.href = this.link
    },

    //获取用户数据信息
    async getUserInfo () {
      let uid = this.$route.query.uid
      let project = this.$route.query.project
      let origin_ = location.origin
      const res = await axios.get(`${origin_}/backend/verify?uid=${uid}&project=${project}`)
      // const res = await axios.get(`https://dev.poster.ceba.ceshiren.com/backend/dev/verify?uid=${uid}`)
      console.log(res);
      if (res.data.errcode) {
        this.flag = true
        this.errmsg = res.data.errmsg
        return
      }
      if (res.data.active) {
        this.active = res.data.active
        this.user = res.data.user
        this.refer = res.data.refer
        this.title = res.data.title
        this.query = res.data.poster
        this.img_src = res.data.img_src
        this.link_text = res.data.link_text
        if (res.data.profile_link) this.profile_link = res.data.profile_link
        else this.profile_link = '' //默认值
        //请求到数据后 iframe加载poster页面
        this.iframeUrl = `${origin_}/poster?project=${res.data.poster.project}&refer=${res.data.poster.refer}`
      } else {
        this.active = res.data.active
        this.deactive_img = res.data.deactive_img
      }

      //对于学院系统相关内容
      this.flag_student = res.data.non_assist.flag
      if (res.data.non_assist.flag) {
        this.text = res.data.non_assist.text
        this.link = res.data.non_assist.link
      }


    },

    //昵称处理
    nicknameFn (nickname, num) {
      if (nickname.length > num) {
        return nickname.substring(0, num) + '···'
      } else {
        return nickname
      }
    },

    //跳转poster页面
    goPoster () {
      this.$router.push({ path: 'poster', query: this.query })
    },

    //跳转my页面
    goMy () {
      this.$router.push({ path: 'my', query: this.query })
    }
  },
  created () {
    this.getUserInfo()
  },
  mounted () {
    console.log(this.title);
    if (this.title) document.title = this.title
    // else document.title = ''
  },
  watch: {
    title: {
      handler (newval, oldval) {
        if (newval !== oldval) document.title = this.title
      }
    }
  }
}
</script>

<style scoped>
.tip-box {
  width: 100%;
  margin-top: 20%;
}

.poster {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  opacity: 0;
}
.deactive_img {
  object-fit: contain;
  max-width: 375px;
  max-height: 670px;
  position: absolute;
  z-index: 99;
  left: 50%;
  transform: translateX(-50%);
}
.v-application--wrap p {
  line-height: 1.5;
}
</style>